<template>
  <section class="main">
    <el-form
      :label-position="'left'"
      label-width="150px"
      @submit.prevent="onSubmit"
      style="margin: 20px; width: 60%; min-width: 600px"
    >
      <el-form-item label="subjectCourseId">
        <el-input v-model="subjectCourseId"> </el-input>
      </el-form-item>
      <el-form-item label="试卷类型">
        <el-select v-model="tikuType" placeholder="请选择">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="试卷标题">
        <el-input v-model="title"> </el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSearch">搜索</el-button>
      </el-form-item>
    </el-form>

    <div class="table">
      <div style="text-align:left" v-if="waiting">
        <el-skeleton :rows="6" animated />
      </div>
      <el-table :data="tableData" highlight-current-row style="width: 100%" v-else>
        <el-table-column label="id" width="200">
          <template slot-scope="scope">
            <div v-html="scope.row.id"></div>
          </template>
        </el-table-column>
        <el-table-column label="题干" width="200">
          <template slot-scope="scope">
            <div v-html="scope.row.title"></div>
          </template>
        </el-table-column>

        <el-table-column label="pdf">
          <template slot-scope="scope">
            <a v-if="scope.row.pdfKey" :href="scope.row.pdfUrl" target="_blank">{{ scope.row.pdfKey }}</a>
          </template>
        </el-table-column>
        <el-table-column label="word">
          <template slot-scope="scope">
            <a v-if="scope.row.wordKey" :href="scope.row.wordUrl" target="_blank">{{ scope.row.wordKey }}</a>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button size="small" @click="onEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button size="small" @click="onDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-count="totalPage"
        @current-change="onChangePage"
      ></el-pagination>
    </div>
  </section>
</template>

<script>
import { tikuApi } from "@/api/tikuApi";
import { epochToDateString } from "@/util/TimeUtil";
import msgLogger from "@/util/msgLogger";
import COS from "cos-js-sdk-v5";

const LEN = 10;

export default {
  data() {
    return {
      options: [
        { label: "真题", value: 0 },
        { label: "套卷", value: 1 },
      ],
      subjectCourseId: 0,
      title: "",
      tikuType: 0,
      tableData: [],

      currentPage: 1,
      totalPage: 1,
      start: 0,
      len: 15,
      waiting: false,
    };
  },
  mounted() {},
  methods: {
    formatTime: function(row, column) {
      return epochToDateString(row[column.property]);
    },
    onSearch() {
      //subjectCourseId, tikuType, title, start, len
      this.waiting = true;
      tikuApi
        .simpleTikuSearch(this.subjectCourseId, this.tikuType, this.title, this.start, this.len)
        .then((ret) => {
          console.log("ret", ret);
          let { code, data } = ret;
          if (code == 0) {
            this.tableData = data;
            this.waiting = false;
          } else {
            msgLogger.error("出错了" + ret.msg);
          }
        })
        .catch((err) => {
          msgLogger.error("出错了" + err);
        });
    },
    onChangePage(pageNo) {
      this.currentPage = pageNo;
      this.start = (this.currentPage - 1) * this.len;
      this.onSearch();
    },
    onEdit(index, row) {
      this.$router.push({
        name: "tiku-simple-add-edit",
        query: {
          id: row.id,
          subjectCourseId: row.subjectCourseId,
        },
      });
    },
    onDelete(index, row) {
      this.$confirm("确定删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log("....delete...", row);
          tikuApi.simpleTikuDelete(row.subjectCourseId, row.id).then((res) => {
            console.log("......", res);
            if (res.code == 0 && res.data) {
              msgLogger.done("删除成功");
              this.tableData.splice(index, 1);
            } else {
              msgLogger.warn(res.msg);
            }
          });
        })
        .catch(() => {});
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.main {
  display: flex;
  flex-direction: column;
  width: 80%;
}
</style>
